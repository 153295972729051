import React, { useEffect, useState } from 'react';
import { ICorruptionCaseDTO } from '../../types/ICorruptionCase';
import axios from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { provinces } from '../../types';
import { log } from 'console';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../store/reducers';
import FormGroup from '../../components/FormGroup';

// import { Container } from './styles';

enum ListFilter {
  ALL,
  IMPORTANT,
  COURT,
  PUBLIC_MINISTRY,
}

const List: React.FC = () => {
  // const API_URL = 'http://localhost:3001';
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);

  const PATH = 'corruption-case';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [firstRender, setFirstRender] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [corruptionCasesMainList, setCorruptionCasesMainList] = useState<
    ICorruptionCaseDTO[]
  >([]);
  const [corruptionCases, setCorruptionCases] = useState<ICorruptionCaseDTO[]>(
    []
  );
  const location = useLocation();
  const [count, setCount] = useState(0);
  const [filterType, setFilterType] = useState<number>(0);

  const getList = async () => {
    // const courtId = searchParams.get('courtId');
    const province = searchParams.get('province');
    const categoryId = searchParams.get('categoryId');
    const year = searchParams.get('year');

    if (province === null || categoryId === null) {
      try {
        dispatch({ type: 'SET_LOADING', loading: true });

        const result = await axios.get(`${API_URL}/${PATH}`);
        setCorruptionCasesMainList(result.data);
        setCorruptionCases(result.data);
        dispatch({ type: 'SET_LOADING', loading: false });
      } catch (error) {
        dispatch({ type: 'SET_LOADING', loading: false });
        console.error(error);
      }
    } else {
      try {
        dispatch({ type: 'SET_LOADING', loading: true });
        const result = await axios.get<ICorruptionCaseDTO[]>(
          `${API_URL}/corruption-case/search?province=${province}&categoryId=${categoryId}&year=${year}`
        );
        setCorruptionCasesMainList(result.data);
        setCorruptionCases(result.data);
        dispatch({ type: 'SET_LOADING', loading: false });
      } catch (error) {
        dispatch({ type: 'SET_LOADING', loading: false });
        console.error(error);
      }
    }
  };

  const filterList = (key: ListFilter) => {
    let filtered: ICorruptionCaseDTO[] = [];
    switch (key) {
      case ListFilter.IMPORTANT:
        filtered = corruptionCasesMainList.filter((process) => {
          return process.priority === 1;
        });
        break;
      case ListFilter.PUBLIC_MINISTRY:
        filtered = corruptionCasesMainList.filter((process) => {
          return process.court_type === 2;
        });
        break;
      case ListFilter.COURT:
        filtered = corruptionCasesMainList.filter((process) => {
          return process.court_type === 1;
        });
        break;
      case ListFilter.ALL:
        filtered = corruptionCasesMainList;
        break;

      default:
        break;
    }
    setCorruptionCases(filtered);
  };

  const processFontColor = (process: ICorruptionCaseDTO) => {
    if (process.priority === 1) {
      return 'font-color-danger';
    } else if (process.court_type === 2) {
      return 'font-color-success';
    }

    return '';
  };

  useEffect(() => {
    // execute on location change
    getList();
  }, [location]);

  useEffect(() => {
    switch (filterType) {
      case 0:
        filterList(ListFilter.ALL);
        break;
      case 1:
        filterList(ListFilter.IMPORTANT);
        break;
      case 2:
        filterList(ListFilter.COURT);
        break;
      case 3:
        filterList(ListFilter.PUBLIC_MINISTRY);
        break;
      default:
        break;
    }
  }, [filterType]);

  useEffect(() => {}, []);

  return (
    <div className="">
      <div className="col flex-1">
        <div className="_card">
          {/* <div>
            <div className="search-bar-wrapper">
              <i className="fa fa-search"></i>
              <input className="search-bar" type="text" />
            </div>
            <div className="filter">
              Província:
              <select name="" id="">
                {provinces.map((province) => (
                  <option key={province.value}>{province.name}</option>
                ))}
              </select>
            </div>
          </div> */}

          <div className="_card-header">
            Lista de todos Processos ({searchParams.get('province')} -
            {searchParams.get('year') === '0'
              ? ' Todos os anos'
              : searchParams.get('year')}
            ) <br />
            <br />{' '}
            <span
              className="case-legend font-color-danger "
              onClick={() => {
                setFilterType(ListFilter.IMPORTANT);
              }}
            >
              {' '}
              <span className="line line-red">•</span>Casos de destaque
            </span>
            <span
              className="case-legend"
              onClick={() => {
                setFilterType(ListFilter.COURT);
              }}
            >
              {' '}
              <span className="line line-normal">•</span>Casos no Tribunal
            </span>
            <span
              className="case-legend font-color-success"
              onClick={() => {
                setFilterType(ListFilter.PUBLIC_MINISTRY);
              }}
            >
              {' '}
              <span className="line line-normal ">•</span>Casos no Ministério
              Público
            </span>
            <FormGroup>
              <label htmlFor="">Filtro:</label>
              <select
                name=""
                id=""
                value={filterType}
                onChange={(e) => {
                  setFilterType(parseInt(e.target.value));
                }}
              >
                <option value="0">Mostrar Todos</option>
                <option value="1">Em destaque</option>
                <option value="2">Tribunal</option>
                <option value="3">Ministério Público</option>
              </select>
            </FormGroup>
          </div>
          <div className="_card-body">
            <div className="faze-list">
              {corruptionCases.map((corruptionCase) => {
                return (
                  <div
                    // className={`item ${corruptionCase.priority === 1 ? 'important-cases ' : ''
                    //   }`}
                    className={`item ${processFontColor(corruptionCase)}`}
                    key={corruptionCase.id}
                    onClick={() => {
                      // setCorruptionCase(corruptionCase);
                      // setShowCorruptionCase(true);
                      navigate('/public/corruption-case/' + corruptionCase.id);
                    }}
                  >
                    {corruptionCase.crime} ({corruptionCase.process_number})
                  </div>
                );
              })}

              {corruptionCases.length === 0 && (
                <div className="item important-cases">
                  ------- SEM DADOS QUE CORRESPONDEM A FILTRAGEM -------
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
