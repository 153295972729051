export interface IMedicine {
  _id: any;
  code: string;
  name: string;
  type: string;
  category: string;
  description: string;
}

export type CategoryQuant = {
  _count: { category_id: number };
  category_id: number;
};
export interface IOccurrence {
  _id: any;
  sanitaryUnityID: string;
  sanitaryUnity: ISanitaryUnity[];
  medicineIDs: string[];
  medicines: IMedicine[];
  date: Date;
  description: string;
}

export interface ISanitaryUnity {
  _id: any;
  code: string;
  name: string;
  type: string;
  category: string;
  region: string;
  province: string;
  address: string;
  description: string;
}

export const gender = [
  {
    name: 'Masculino',
    value: 'M',
  },
  {
    name: 'Feminino',
    value: 'F',
  },
];

export interface IProvincesCount {
  _count: number;
  province: string;
}

export const provinces = [
  {
    name: 'MAPUTO CIDADE',
    value: 'MAPUTO_CIDADE',
  },
  {
    name: 'MAPUTO PROVÍNCIA',
    value: 'MAPUTO_PROVINCIA',
  },
  {
    name: 'GAZA',
    value: 'GAZA',
  },
  {
    name: 'INHAMBANE',
    value: 'INHAMBANE',
  },
  {
    name: 'SOFALA',
    value: 'SOFALA',
  },
  {
    name: 'MANICA',
    value: 'MANICA',
  },
  {
    name: 'ZAMBEZIA',
    value: 'ZAMBEZE',
  },
  {
    name: 'TETE',
    value: 'TETE',
  },
  {
    name: 'NAMPULA',
    value: 'NAMPULA',
  },
  {
    name: 'NIASSA',
    value: 'NIASSA',
  },
  {
    name: 'CABO-DELGADO',
    value: 'CABO_DELGADO',
  },
];

export const provincesArray = {
  MAPUTO_CIDADE: 'MAPUTO CIDADE',

  MAPUTO_PROVINCIA: 'MAPUTO PROVÍNCIA',

  GAZA: 'GAZA',

  INHAMBANE: 'INHAMBANE',

  SOFALA: 'SOFALA',

  MANICA: 'MANICA',

  ZAMBEZE: 'ZAMBEZE',

  value: 'TETE',

  NAMPULA: 'NAMPULA',

  name: 'NIASSA',

  CABO_DELGADO: 'CABO-DELGADO',
};

export const PHASE = [
  'Instrução preparatória',
  'Instrução contraditória',
  'Julgamento',
  'Despacho de pronúncia',
  'Recurso',
  'Terminado (Trânsito em Julgado)',
];
