import React, { useState } from 'react';
import logo from '../../assets/img/Logo-cip-completo-01.png';
import { useNavigate } from 'react-router-dom';

// import { Container } from './styles';

const Welcome: React.FC = () => {
  const navigate = useNavigate();
  const [goToLoginCount, setgoToLoginCount] = useState(0);
  return (
    <>
      <div className="welcome">
        <div className="wrapper">
          <div className="welcome-title">
            <div
              onClick={() => {
                if (goToLoginCount >= 5) {
                  navigate('/login');
                }
                setgoToLoginCount(goToLoginCount + 1);
              }}
            >
              <img src={logo} alt="" />
            </div>
            <h1>Base de dados (amostra) de Casos de Corrupção</h1>
          </div>
          <div className="message">
            A presente base de dados não é exaustiva, no sentido de que não
            cobre todos os casos de corrupção que são tramitados pelos órgãos de
            administração da justiça. A mesma constitui uma amostra, visando
            destacar a tendência da corrupção em Moçambique
          </div>
          <br />
          <div>
            <button
              className="button button-primary"
              onClick={() => {
                navigate('/public');
              }}
            >
              <h2>Aceder a base de dados</h2>
            </button>
          </div>
          <br />
          <br />
          <br />
          {/* <h2>Parceiros</h2>
          <div className="sponsors">
            <div className="sponsor"></div>
            <div className="sponsor"></div>
            <div className="sponsor"></div>
            <div className="sponsor"></div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Welcome;
